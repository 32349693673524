import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Report } from '../../views/dashboard/model/report';
import { environment } from '../../../../environments/environment';
import { DataService } from "src/app/services/data.service";
import { CommonModule, getLocaleTimeFormat } from '@angular/common';
import { Router, CanActivate } from '@angular/router';
import { result } from 'underscore';
import cronstrue from 'cronstrue';
import { MatPaginator } from '@angular/material/paginator';
interface MonthName {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-status-report',
  templateUrl: './status-report.component.html',
  styleUrls: ['./status-report.component.scss']
})
export class StatusReportComponent implements OnInit {
  @ViewChild('scheduledOrdersPaginator')
  paginator!: MatPaginator;
  labelPosition = 'after';
  model;
  icons = JSON.parse(localStorage.getItem('icons')) || [];
  showDelete: boolean;
  environment: any;
  showIframe: boolean;
  avgMins: any;
  avgSecs: any;
  constructor(public dialogRef: MatDialogRef<StatusReportComponent>, @Inject(MAT_DIALOG_DATA) public data, private dataService: DataService, private router: Router) {
    this.environment = environment;
    this.showIframe = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    dialogRef.disableClose = true;


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      this.showIframe = false;
    }
    else if (/Chrome/i.test(navigator.userAgent)) {
      this.showIframe = true;
    }
    else if (/Safari/i.test(navigator.userAgent))
      this.showIframe = false;
  }


  loader = false;
  originalData: any;
  monthName: MonthName[] = [
    { value: '01', viewValue: 'Jan' },
    { value: '02', viewValue: 'Feb' },
    { value: '03', viewValue: 'Mar' },
    { value: '04', viewValue: 'Apr' },
    { value: '05', viewValue: 'May' },
    { value: '06', viewValue: 'Jun' },
    { value: '07', viewValue: 'Jul' },
    { value: '08', viewValue: 'Aug' },
    { value: '09', viewValue: 'Sep' },
    { value: '10', viewValue: 'Oct' },
    { value: '11', viewValue: 'Nov' },
    { value: '12', viewValue: 'Dec' },
  ];
  pipeLineName = [
    { value: '01', viewValue: 'SQL source' },
    { value: '02', viewValue: 'Snowflake source' }
  ];

  recordsArray = [20, 40, 60, 80, 100];
  currentMonth: any;
  selectedMonth: any;
  getMonth: any;
  dropDownChanges = false;
  selectRecordsCount = 20;
  srcOld = false;
  getColor(success, last_updated) {
    if (success === 'Succeeded' && last_updated >= new Date(Date.now() - 86400 * 1000).toISOString()) return "green";
    if (success === 'Succeeded' && last_updated >= new Date(Date.now() - 86400 * 1000 * 7).toISOString()) return "green";
    if (success === 'Succeeded' && last_updated <= new Date(Date.now() - 86400 * 1000).toISOString()) return "green";
    return "red";
  }
  sqlData: any;
  ngOnInit() {
    this.getStatusReport('currentMonth', 'no')

  }
  addReportError() {
    window.location.reload();
  }
  selectPipeline(event) {
    if (event.value == '01') {
      this.loader = true;
      this.dataService.getJobLogs().subscribe((model) => {
        this.srcOld = true;
        this.loader = false;
        this.sqlData = model;
      });
    } else {
      this.getStatusReport('currentMonth', 'no')
    }
  }
  dropDownChangeArray(event) {
    console.log(event.value);
    this.selectRecordsCount = event.value;
  }

  dropDownChange(event) {
    this.dropDownChanges = true
    console.log(event.value);
    this.selectedMonth = event.value;
    this.getStatusReport(this.selectedMonth, 'yes');
  }
  getStatusReport(selectMonth, dropdown) {
    this.loader = true;
    this.currentMonth = new Date().getMonth() + 1;
    this.currentMonth = (this.currentMonth < 10) ? '0' + this.currentMonth : this.currentMonth;
    this.getMonth = (selectMonth === 'currentMonth') ? this.currentMonth : selectMonth;
    this.dataService.getStatusReport(this.getMonth, dropdown).subscribe((data) => {
      this.originalData = data;
      this.loader = false;
      this.srcOld = false;
      // console.log('---Status', data);
      var uniqueArray = this.removeDuplicates(data, "DAG_ID");
      this.model = uniqueArray;
      if (this.model.length > 100) {
        this.recordsArray.push(this.model.length)
      }

      // console.log('---Status After', this.model);
      if (this.dropDownChanges) {
        this.filteredArray = this.originalData.map(({ SF_TABLE_NAME, SF_TABLE_LOAD_STATUS, DAG_SCHEDULE_TIMING, DAG_SCHEDULE_FREQUENCY, RECORDS_UPDATED_DURING_RUN, DAG_NEXT_SCHEDULED_RUN, ...rest }) => rest);
        for (var i in this.filteredArray) {
          this.startDataArray.push({
            "DAG_ID": this.filteredArray[i].DAG_ID,
            "DAG_START_DATE": this.filteredArray[i].DAG_START_DATE,
            "DAG_END_DATE": this.filteredArray[i].SF_TABLE_LOAD_TIME
          });
        }
        // console.log('StartTime', this.startDataArray)

      }
      this.dropDownChanges = false;
    });
  }
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  getTimeFn(date) {
    // var getDate = new Date(date);
    let getTime = (date.split('.')[0]) ? date.split('.')[0] : date;
    // console.log('rowDate', getDate.toISOString().split('.')[0])
    // var todayDate = new Date(date).toLocaleString()
    return getTime
  }
  filteredArray: any;
  startDataArray = [];
  getAvgTime(strDate, endDate, newObj) {
    // alert(this.startDataArray.length)
    if (this.startDataArray.length > 0) {
      var newArray = [];
      for (var i in this.startDataArray) {
        if (this.startDataArray[i].DAG_ID == newObj.DAG_ID) {
          if (this.startDataArray[i].DAG_START_DATE !== null && this.startDataArray[i].DAG_END_DATE !== null) {
            var sdh = new Date(this.startDataArray[i].DAG_START_DATE);
            var sDH = sdh.getHours();
            var edh = new Date(this.startDataArray[i].DAG_END_DATE);
            var eDH = edh.getHours();
            var getHours = eDH - sDH;

            var sdm = new Date(this.startDataArray[i].DAG_START_DATE);
            var sDM = sdm.getMinutes();
            var edm = new Date(this.startDataArray[i].DAG_END_DATE);
            var eDM = edm.getMinutes();
            var getMinutes = eDM - sDM;

            var sds = new Date(this.startDataArray[i].DAG_START_DATE);
            var sDS = sds.getSeconds();
            var eds = new Date(this.startDataArray[i].DAG_END_DATE);
            var eDS = eds.getSeconds();
            var getSeconds = eDS - sDS;
            var getExactHours = (getHours < 10) ? '0' + getHours : getHours;
            var getExactMinutes = (getMinutes < 10) ? '0' + getMinutes : getMinutes;
            var getExactSeconds = (getSeconds < 10) ? '0' + getSeconds : getSeconds;
            var avg = getExactHours + (':') + getExactMinutes + (':') + getExactSeconds;
            newArray.push(avg);
          }
          // console.log('GetArrayTime', strDate)
          // console.log('GetArrayTime', endDate)
        }
      }

      // console.log('-------', newArray)
      return this.getAverageTime(newArray)
    } else {
      // const start = new Date(strDate).getTime();
      // const end = new Date(endDate).getTime();
      // const diff = end - start;
      // const seconds = Math.floor(diff / 1000 % 60);
      var sdh = new Date(strDate);
      var sDH = sdh.getHours();
      var edh = new Date(endDate);
      var eDH = edh.getHours();
      var getHours = eDH - sDH;

      var sdm = new Date(strDate);
      var sDM = sdm.getMinutes();
      var edm = new Date(endDate);
      var eDM = edm.getMinutes();
      var getMinutes = eDM - sDM;

      var sds = new Date(strDate);
      var sDS = sds.getSeconds();
      var eds = new Date(endDate);
      var eDS = eds.getSeconds();
      var getSeconds = eDS - sDS;
      var avg = getHours + (':') + getMinutes + (':') + getSeconds;

      return avg
    }

  }
  getAverageTime(times) {
    var count = times.length
    var timesInSeconds = [];
    // loop through times
    for (var i = 0; i < count; i++) {
      // parse
      var pieces = times[i].split(':');
      var ampm = pieces[2].split(' ');
      var hrs = Number(pieces[0]);
      var mins = Number(pieces[1]);
      var secs = Number(ampm[0]);
      var ampm = ampm[1];
      // convert to 24 hr format (military time)

      // find value in seconds of time
      var totalSecs = hrs * 60 * 60;
      totalSecs += mins * 60;
      totalSecs += secs;
      // add to array
      timesInSeconds[i] = totalSecs;
    }
    // find average timesInSeconds
    var total = 0;
    // console.log(timesInSeconds);
    for (var j = 0; j < count; j++) {
      total = total + Number(timesInSeconds[j]);
    }
    var avg = Math.round(total / count);
    // console.log('avg secs: ' + avg);
    // turn seconds back into a time
    this.avgMins = Math.floor(avg / 60);
    this.avgSecs = avg - (60 * this.avgMins);
    var avgHrs = Math.floor(this.avgMins / 60);
    // console.log('hours: ' + avgHrs);
    this.avgMins = this.avgMins - (60 * avgHrs);
    // convert back to 12 hr. format

    // add leading zeros for seconds, minutes
    this.avgSecs = ('0' + this.avgSecs).slice(-2);
    this.avgMins = ('0' + this.avgMins).slice(-2);
    // your answer
    return avgHrs + ':' + this.avgMins + ':' + this.avgSecs;
  }


  getDagSch(dag_sch) {
    // console.log('Dag', dag_sch)
    let getTime = cronstrue.toString(dag_sch);
    return getTime
  }
  getColorSf(success) {
    if (success === 'success') return "green";
    // if (success === 'Succeeded' && last_updated >= new Date(Date.now() - 86400 * 1000 * 7).toISOString()) return "green";
    // if (success === 'Succeeded' && last_updated <= new Date(Date.now() - 86400 * 1000).toISOString()) return "green";
    return "red";
  }

}


