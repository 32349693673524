import { ThemePalette } from "@angular/material/core";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";

import {
  Component,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from "@angular/animations";

@Component({
  selector: "app-trim-view",
  templateUrl: "./trim-view.component.html",
  styleUrls: ["./trim-view.component.scss"],
  animations: [
    trigger("flyInOut", [
      transition("*=>inmore", [
        style({ transform: "translateX(100%)" }), //start in
        animate(200),
      ]),
      transition("*=>inless", [
        style({ transform: "translateX(-100%)" }), //start in
        animate(200),
      ]),
      transition("*=>outless", [
        animate(200, style({ transform: "translateX(100%)" })), //end in
      ]),
      transition("*=>outmore", [
        animate(200, style({ transform: "translateX(-100%)" })), //end in
      ]),
    ]),
  ],
})
export class TrimViewComponent implements OnInit {
  index = 0;
  indexOld = 0;
  maxHeigth: string = "";
  animationDone = false;
  @ViewChildren("tab") tabs: QueryList<ElementRef> | undefined;
  constructor(public dialog: MatDialog) { }
  change(tab: any, index: number) {
    if (
      tab.selectedIndex != this.indexOld &&
      !confirm("If you change Tabs, all changes will be lost.")
    )
      tab.selectedIndex = this.indexOld;
    else {
      this.index = index;
    }
  }

  getAnimation(i: number, indexOld: number, index: number) {
    const from = i == index ? "in" : i == indexOld ? "out" : null;
    if (from && indexOld != index)
      return from + (indexOld > index ? "less" : "more");
    return null;
  }
  update(event: MatTabChangeEvent) {
    console.log(event.tab.content);
  }
  ngOnInit(): void {
    setTimeout(() => {
      // Code that changes the value
    });
  }
  addReportError() {
    window.location.reload();
  }
  // ngAfterViewInit() {
  //   // setTimeout(()=>{
  //   // this.maxHeigth=this.tabs.map(x=>x.nativeElement.getBoundingClientRect().height)
  //   //              .reduce((a,b)=>a>b?a:b,0)+'px'
  //   // })
  // }
  dialogObj: any;
  tabChangeFn(obj) {
    // console.log(obj.index);
    // this.dialog.open(ConfirmDialogComponent,{
    //   data:{indexId : obj.index,routingChange:true}
    // }).afterClosed().subscribe((confirm) => {
    //     if (confirm) {
    //       console.log(confirm);
    //     }
    //   })
  }
}
