import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-popup-text',
  templateUrl: './popup-text.component.html',
  styleUrls: ['./popup-text.component.scss']
})

export class PopupTextComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PopupTextComponent>, public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data) { 
      dialogRef.disableClose = true;
    }

  @Input('mat-dialog-close')

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


}
