<a *ngIf="!getAdminUrl && link?.name !== 'Competitive Inventory Analytics (CIA)'" href={{link?.URL}} >
  <img src='https://st11798p010.blob.core.windows.net/$web/{{link?.image}}' class="image" />
  <div class="dropdown-content">
    <a  href={{link?.URL}} target="_blank" (click)="exit(link?.URL,link?.name)" [ngClass]="{'vpnOnly': link?.internal}">Open in new tab</a>
    
    <a *ngIf=" link.AllowsIframe" href="" (click)="setURL(link.URL, $event, link?.name)"
      [ngClass]="{'vpnOnly': link?.internal}">Open in iframe</a>

    <a href="https://st11798p010.blob.core.windows.net/$web{{link?.releaseNotesUrl}}" *ngIf="link?.releaseNotesUrl"
      [ngClass]="{'releaseNotes': !!link?.releaseNotesUrl && inLastWeek(link.releaseNotesLastUpdated)}">Release
      Notes</a>
    <button *ngIf="link?.reportOwnerEmail" (click)="requestAccess(link?.name, link?.reportOwnerEmail, $event)">Request
      Access</button>
    <a href='/#/user-preferences?tab=1' *ngIf='link?.id === 16'>
      Configure Alerts
    </a>
    <a href="" (click)="editReport(link, $event)" *ngIf="isAdmin">Edit Report</a>

  </div>
</a>
<a style="cursor: pointer;" *ngIf="!getAdminUrl && link?.name == 'Competitive Inventory Analytics (CIA)'" >
  <img src='https://st11798p010.blob.core.windows.net/$web/{{link?.image}}' class="image" />
  <div class="dropdown-content">
    
    <a (click)="exit(link?.URL,link?.name)" [ngClass]="{'vpnOnly': link?.internal}">Open in new tab</a>

    <!-- <a href={{link?.URL}} target="_blank" (click)="exit(link?.URL,link?.name)" [ngClass]="{'vpnOnly': link?.internal}">Open in new tab</a> -->
    
    <a *ngIf=" link.AllowsIframe" href="" (click)="setURL(link.URL, $event, link?.name)"
      [ngClass]="{'vpnOnly': link?.internal}">Open in iframe</a>

    <a href="https://st11798p010.blob.core.windows.net/$web{{link?.releaseNotesUrl}}" *ngIf="link?.releaseNotesUrl"
      [ngClass]="{'releaseNotes': !!link?.releaseNotesUrl && inLastWeek(link.releaseNotesLastUpdated)}">Release
      Notes</a>
    <button *ngIf="link?.reportOwnerEmail" (click)="requestAccess(link?.name, link?.reportOwnerEmail, $event)">Request
      Access</button>
    <a href='/#/user-preferences?tab=1' *ngIf='link?.id === 16'>
      Configure Alerts
    </a>
    <a href="" (click)="editReport(link, $event)" *ngIf="isAdmin">Edit Report</a>

  </div>
</a>
<a href={{link?.URL}} *ngIf="getAdminUrl">
  <img src='https://st11798p010.blob.core.windows.net/$web/{{link?.image}}' class="image" />
  <div class="dropdown-content">
    <a href="{{link?.URL}}" target="_blank" *ngIf="isAdmin">Link</a>
    <a href="" (click)="editReport(link, $event)" *ngIf="isAdmin">Edit Report</a>
    <!-- <a href="https://selfservice-evolve.intra.chrysler.com/sense/app/6a9af418-5038-4829-9538-4f1b6547ba3b"
      target="_blank" *ngIf="isAdmin">CMA Validation</a> -->
  </div>
</a>
<div class="grid-item-title">{{link?.name}}</div>