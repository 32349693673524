import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
// import {
//   MsalModule,
//   MsalInterceptor,
//   BroadcastService,
//   MsalService,
// } from "@azure/msal-angular";
// import { MsalInterceptor } from '@azure/msal-angular';
// import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

// import Services

import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { StartupService } from "src/app/services/startup.service";
import { ExcelService } from "./services/excel.service";

// import components
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { PopupDashboardappComponent } from "./components/layout/popup-dashboardapp/popup-dashboardApp.component";
import { PopupTextComponent } from "./components/layout/popup-text/popup-text.component";
import { ProvideFeedbackModalComponent } from "./components/layout/provide-feedback-modal/provide-feedback-modal.component";
import { StatusReportComponent } from "./components/layout/status-report/status-report.component";
import { ToolbarComponent } from "./components/layout/toolbar/toolbar.component";
import { DashboardComponent, RequestSnackbarComponent } from "./components/views/dashboard/dashboard.component";
import { LoginComponent } from "./components/views/login/login.component";

import { DrawerComponent } from "./components/layout/drawer/drawer.component";
import { ReportAdminComponent } from "./components/layout/report-admin/report-admin.component";
import { InsightCompetitivePopupComponent } from './components/views/insight-competitive-popup/insight-competitive-popup.component';
import { InsightGridComponent } from './components/views/insight-grid/insight-grid.component';
import { InsightLeadsBreakdownPopupComponent } from './components/views/insight-leads-breakdown-popup/insight-leads-breakdown-popup.component';
import { InsightPinMarketPopupComponent } from './components/views/insight-pin-market-popup/insight-pin-market-popup.component';
import { KpiGridComponent } from "./components/views/kpi-grid/kpi-grid.component";
import { KpiWrapperComponent } from "./components/views/kpi-wrapper/kpi-wrapper.component";
import { KpiComponent } from "./components/views/kpi/kpi.component";



import { AlertPreferenceComponent } from "./components/views/alert-preference/alert-preference.component";
import { SaveSnackbarComponent, UserPreferencesComponent } from "./components/views/user-preferences/user-preferences.component";

// import modules
import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChartsModule } from "ng2-charts";
import { SafePipeModule } from "safe-pipe";
import { AppRoutingModule } from "./app-routing.module";
import { SpinnerOverlayComponent } from "./components/layout/spinner/sppinner-overlay.component";
import { ChipMultiselectComponent } from "./components/subcomponents/chip-multiselect/chip-multiselect.component";
import { MonthTrendChartComponent } from './components/subcomponents/month-trend-chart/month-trend-chart.component';
import { FilterComponent } from "./components/views/filter/filter.component";
import { IdrProspectsComponent } from "./components/views/idr-prospects/idr-prospects.component";
import { NameplateRecapComponent } from "./components/views/nameplate-recap/nameplate-recap.component";
import { CustomHttpInterceptor } from "./custom-httpInterceptor";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { MaterialModule } from "./material.module";
import { CoreErrorHandlerService } from './services/core-error-handler.service';
import { LoaderService } from "./services/loader.service";

import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, LogLevel } from '@azure/msal-browser';
import { TemporaryAlertComponent } from "./components/layout/temporary-alert/temporary-alert.component";
import { ShipmentGridComponent } from './components/view/shipment-view/shipment-grid/shipment-grid.component';
import { ShipmentOverViewComponent } from './components/view/shipment-view/shipment-over-view/shipment-over-view.component';
import { ShipmentRegionViewComponent } from './components/view/shipment-view/shipment-region-view/shipment-region-view.component';
import { AdminViewComponent } from './components/views/admin-view/admin-view.component';
import { InsightComponent } from "./components/views/insight/insight.component";
import { PinMktViewComponent } from "./components/views/pin-mkt-view/pin-mkt-view.component";
import { ReportLinkComponent, RequestSnackbarComponent as RequestSnackbarComponent2 } from "./components/views/report-link/report-link.component";
import { ShareWalkComponent } from "./components/views/share-walk/share-walk.component";

import { AdobeArchiveComponent } from './components/views/adobe-archive/adobearchive.component';
import { TicketComponent } from './components/views/ticket/ticket.component';

import { ConfirmDialogComponent } from "./components/Trim/confirm-dialog/confirm-dialog.component";
import { DealerMappingComponent } from './components/Trim/dealer-mapping/dealer-mapping.component';
import { IndustryGoldStandardComponent } from './components/Trim/industry-gold-standard/industry-gold-standard.component';
import { IndustryStandardMapComponent } from './components/Trim/industry-standard-map/industry-standard-map.component';
import { TrimViewComponent } from './components/Trim/trim-view/trim-view.component';
import { JeepGridComponent } from './components/view/jeep-global/jeep-grid/jeep-grid.component';
import { JeepOverViewComponent } from './components/view/jeep-global/jeep-over-view/jeep-over-view.component';
import { JeepRegionViewComponent } from './components/view/jeep-global/jeep-region-view/jeep-region-view.component';
import { PricePaidDistributionComponent } from "./components/views/ppd/ppd.component";
export function startupServiceFactory(
  startupService: StartupService
): Function {
  return () => startupService.load();
}
export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  if (environment.auth) {
    return new PublicClientApplication({
      auth: environment.auth,
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  else {
    throw new Error('MSAL authentication configuration is missing in environment.');
  }
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}
@NgModule({
  declarations: [
    RequestSnackbarComponent,
    RequestSnackbarComponent2,
    TemporaryAlertComponent,
    SaveSnackbarComponent,
    AppComponent,
    KpiComponent,
    ReportLinkComponent,
    IdrProspectsComponent,
    ShareWalkComponent,
    ChipMultiselectComponent,
    KpiGridComponent,
    InsightPinMarketPopupComponent,
    InsightGridComponent,
    StatusReportComponent,
    AlertPreferenceComponent,
    InsightCompetitivePopupComponent,
    InsightLeadsBreakdownPopupComponent,
    LoginComponent,
    DashboardComponent,
    FooterComponent,
    NameplateRecapComponent,
    DrawerComponent,
    FilterComponent,
    KpiWrapperComponent,
    PricePaidDistributionComponent,
    ToolbarComponent,
    ProvideFeedbackModalComponent,
    PopupTextComponent,
    PopupDashboardappComponent,
    ReportAdminComponent,
    UserPreferencesComponent,
    InsightComponent,
    SpinnerOverlayComponent,
    MonthTrendChartComponent,
    ShipmentGridComponent,
    ShipmentOverViewComponent,
    ShipmentRegionViewComponent,
    PinMktViewComponent,
    AdminViewComponent,
    TicketComponent,
    AdobeArchiveComponent,
    JeepGridComponent,
    JeepRegionViewComponent,
    JeepOverViewComponent,
    DealerMappingComponent,
    ConfirmDialogComponent,
    IndustryGoldStandardComponent,
    IndustryStandardMapComponent,
    TrimViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SafePipeModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    MsalModule,
    BrowserAnimationsModule,
    MaterialModule,
    OverlayModule,
    ChartsModule,
  ],
  providers: [
    DataService,
    AuthService,
    StartupService,
    LoaderService,
    ExcelService,
    { provide: ErrorHandler, useClass: CoreErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
