<div >
 <h4>Warning: Data Mapping</h4>
 <h6>Application Migration</h6>
 <p>We have developed PowerBI applications to replace the Qlikview application CIA. Please transition to the new applications for accurate and up-to-date information.</p>
 <p>reach out to Vanessa Dowd (vanessa.dowd@stellantis.com) if you have any questions or concerns.</p>
<!-- <p>The nameplate data within this application is outdated and may be inaccurate. We have developed new PowerBI applications to replace this one. Please transition to the new applications for accurate and up-to-date information. We plan on turning off this application on 01/03/2025. Please reach out to Vanessa Dowd (vanessa.dowd@stellantis.com) if you have any questions or concerns.</p> -->
<p><a href="https://app.powerbi.com/groups/me/apps/38ac1c3d-ebf7-4632-a861-6b2180b4c2e1/reports/68dec2e5-8898-4407-a32d-41a3e036b868?experience=power-bi" target="_blank"> Inventory Trend - Power BI </a> </p>
<p><a href="https://app.powerbi.com/groups/me/apps/38ac1c3d-ebf7-4632-a861-6b2180b4c2e1/reports/bd5ca213-a418-4ad8-b49d-aa3f7150e51f/ReportSection?experience=power-bi" target="_blank"> Pricing Bubble Chart - Power BI </a></p>
<p><a href="https://app.powerbi.com/groups/me/apps/38ac1c3d-ebf7-4632-a861-6b2180b4c2e1/reports/95ee32e6-b525-4d48-afba-57d7fd014e19/ReportSection?experience=power-bi" target="_blank"> CIA Sales Pulse - Power BI </a></p>



  <!-- <input matInput [(ngModel)]="data" type="text"> -->
  <div>
    <button mat-flat-button class="closeButton" (click)="close()">Close</button>
    <button mat-flat-button color="primary" class="submitButton" (click)="submitBtn(data)">OK</button>
  </div>
</div>