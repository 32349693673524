import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { PopupDashboardappComponent } from "../../layout/popup-dashboardapp/popup-dashboardApp.component";
import { ReportAdminComponent } from '../../layout/report-admin/report-admin.component';
import { Report } from '../dashboard/model/report';
//import { bcMapping } from "src/bc-mapping";

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'request-snackbar',
  template: '<span class=\"request-snackbar\">We\'ve requested access for you.</span>'
})
export class RequestSnackbarComponent {

}

@Component({
  selector: "app-report-link",
  templateUrl: "./report-link.component.html",
  styleUrls: ["./report-link.component.scss"],
})


export class ReportLinkComponent implements OnInit {
  constructor(
    public reportService: DataService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private drawerService: DrawerService,
    private loaderService: LoaderService,
    private userService: UserService,
    private titleService: Title,
    private router: Router
  ) {

  }
  getAdminUrl = false;
  ngOnInit() {
    this.getAdminUrl = (this.router.url === '/admin') ? true : false;
  }
  @Input() link;

  @Input() isAdmin;
  editReport(report: Report, event: Event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(ReportAdminComponent, {
      width: '480px',
      data: report
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result?.delete) {
        this.reportService.deleteReport(result.model);
      } else if (result) {
        this.reportService.editReport(result);
      }
    });
  }
  setURL(url: string, event: Event, name: string) {
    event.preventDefault();

    (window as any).gtag('event', 'open iframe', {
      'event_category': 'open iframe',
      'event_label': url
    });

    this.reportService.setIframeUrl(url, event, name)
  }


  openSnackBar() {
    this.snackBar.openFromComponent(RequestSnackbarComponent, {
      duration: 5 * 1000,
    });
  }

  inLastWeek(date) {
    return date >= new Date(Date.now() - 86400 * 1000 * 7).toISOString()
  }

  requestAccess(owner, report, event) {
    event.preventDefault();
    this.openSnackBar();

    this.reportService.requestAccess(owner, report).subscribe();
  }

  exit(destination, name) {
    // console.log(name);
    
    if(name == 'Competitive Inventory Analytics (CIA)'){
      // alert();
      const dialogRef = this.dialog.open(PopupDashboardappComponent, {
        width: '480px',
        data: destination
      });
    }
    else{
      (window as any).gtag('event', 'exit site', {
        'event_category': 'exit link',
        'event_label': destination
      });
    }
  }
}


