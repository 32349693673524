import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-popup-dashboardapp',
  templateUrl: './popup-dashboardapp.component.html',
  styleUrls: ['./popup-dashboardapp.component.scss']
})

export class PopupDashboardappComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PopupDashboardappComponent>, public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data) { 

      dialogRef.disableClose = true;
    }

  @Input('mat-dialog-close')

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
  submitBtn(destination){
    
    // (window as any).gtag('event', 'exit site', {
    //   'event_category': 'exit link',
    //   'event_label': destination
    // });
    window.open(destination, '_blank');
  }


}
